import api from '../clients/axios';

const resource = 'attributes';

export default {
  async getAllAttributes() {
    const response = await api.get(`${resource}`);
    return response.data;
  },
};
