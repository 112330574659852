import { Message } from 'element-ui';
import Repository from '../../services/repositories/RepositoryFactory';

const Documents = Repository.service('documents');

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  state: {
    documents: null,
    total: 0,
    countries: [],
    criterions: [],
    seriesArr: [],
    docParams: {
      skip: 0,
      limit: 20,
    },
    docFilterParams: {
      dates: null,
      author: null,
      authorLocation: null,
      receiverLocation: null,
      text: null,
      searchCountry: null,
      series: null,
      searchCriterion: null,
      searchQuery: null,
      discard: null,
      reviewed: null,
      historicalMemory: null,
      imageOfPast: null,
      politicalEducation: null,
    },
  },
  // ----------------------------------------------------------------------------------
  getters: {
    showDocuments: (state) => state.documents,
    showTotal: (state) => state.total,
    showCountries: (state) => state.countries,
    showCriterions: (state) => state.criterions,
    showSeries: (state) => state.seriesArr,
    showParams: (state) => state.docParams,
    showFilterParams: (state) => state.docFilterParams,
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setDocuments(state, payload) {
      state.documents = payload;
    },
    setParams(state, payload) {
      state.docParams = payload;
    },
    setFilterParams(state, payload) {
      state.docFilterParams = payload;
    },
    modifyDocument(state, payload) {
      state.documents.forEach((document, index) => {
        if (document._id === payload._id) {
          state.documents.splice(index, 1, payload);
        }
      });
    },
    modifyTotal(state, payload) {
      state.total = payload.data;
    },
    modifyCountries(state, payload) {
      state.countries = payload.data.searchCountry;
    },
    modifyCriterions(state, payload) {
      state.criterions = payload.data.searchCriterion;
    },
    modifySeries(state, payload) {
      state.seriesArr = payload.data.series;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async getAllDocuments({ commit }) {
      const docParams = this.getters['documents/showParams'];
      try {
        const response = await Documents.getAllDocuments(docParams);
        const responseTotal = await Documents.getTotalNumberDocuments();
        const responseValues = await Documents.getValuesToFilter();
        commit('setDocuments', response.data);
        commit('modifyTotal', responseTotal);
        commit('modifyCountries', responseValues);
        commit('modifyCriterions', responseValues);
        commit('modifySeries', responseValues);
        return response;
      } catch (error) {
        Message.error({
          message: 'Error getting documents, please try again.',
          duration: 5000,
          showClose: true,
        });
        return console.error(error);
      }
    },
    async getFilterDocuments({ commit }) {
      const docFilterParams = this.getters['documents/showFilterParams'];
      Object.entries(this.getters['documents/showParams']).forEach((arr) => {
        // eslint-disable-next-line prefer-destructuring
        docFilterParams[arr[0]] = arr[1];
      });
      try {
        const response = await Documents.getFilterDocuments(docFilterParams);
        const responseTotal = await Documents.getFilterNumberDocuments(docFilterParams);
        commit('setDocuments', response);
        commit('modifyTotal', responseTotal);
        return response;
      } catch (error) {
        Message.error({
          message: 'Error filtering documents, please try again.',
          duration: 5000,
          showClose: true,
        });
        return console.error(error);
      }
    },
    async getParams({ commit }, body) {
      commit('setParams', body);
      return body;
    },
    async getFilterParams({ commit }, body) {
      const bodyParams = this.getters['documents/showFilterParams'];
      Object.entries(body).forEach((arr) => {
        // eslint-disable-next-line prefer-destructuring
        bodyParams[arr[0]] = arr[1];
      });
      Object.entries(this.getters['documents/showParams']).forEach((arr) => {
        // eslint-disable-next-line prefer-destructuring
        bodyParams[arr[0]] = arr[1];
      });
      commit('setFilterParams', bodyParams);
      return body;
    },
    async deleteDocument(_, id) {
      try {
        const response = await Documents.deleteDocument(id);
        return response;
      } catch (error) {
        Message.error({
          message: 'Error deleting document, please try again.',
          duration: 5000,
          showClose: true,
        });
        return console.error(error);
      }
    },
    async patchDocument(_, payload) {
      try {
        const response = await Documents.patchDocument(payload);
        return response;
      } catch (error) {
        Message.error({
          message: 'Error modifying document, please try again.',
          duration: 5000,
          showClose: true,
        });
        return console.error(error);
      }
    },
  },
};
