const HomeRoute = [
  {
    path: "/",
    name: "Home",
    component: () => import("../../views/Home/Index.vue"),
    children: [
      {
        path: "twitter",
        name: "twitter",
        component: () => import("../../views/Home/Index.vue"),
      },
      {
        path: "google",
        name: "google",
        component: () => import("../../views/Home/Index.vue"),
      },
      {
        path: "youtube",
        name: "youtube",
        component: () => import("../../views/Home/Index.vue"),
      },
      {
        path: "entrevista",
        name: "entrevista",
        component: () => import("../../views/Home/Index.vue"),
      },
    ],
  },
  {
    path: "/graficas",
    name: "graficas",
    component: () => import("../../views/Charts/Index.vue"),
  },
];

export default HomeRoute;
