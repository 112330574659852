// --------------- Element UI import --------------- //
import { MessageBox } from "element-ui";

// -------------- API import ------------------- //
import api from "../clients/axios";

// -------- Base URL for the API -------------- //
const BASE_URL = "v1/analysis";

export default {
  async getChart(filter, series, source, chartType) {
    const FORM_DATA = filter == null ? { series, source } : { ...filter, series, source };
    try {
      const RESPONSE = await api.post(`${BASE_URL}/${source}/${chartType}`, FORM_DATA);
      return RESPONSE.data;
    } catch (error) {
      // Show an alert to the user
      MessageBox.alert("No se encontraron datos con los filtros seleccionados.", {
        type: "warning",
        confirmButtonText: "OK",
      });
      return null;
    }
  },
};
