// import { get } from "core-js/core/dict";
import api from '../clients/axios';

const resource = 'v1/documents/';

export default {
  async getAllDocuments(body) {
    const response = await api.get(`${resource}`, body);
    return response;
  },
  async getFilterDocuments(body) {
    const response = await api.post(`${resource}filter`, body);
    return response.data;
  },
  async getFilterNumberDocuments(body) {
    const response = await api.post(`${resource}filter/count`, body);
    return response;
  },
  async getTotalNumberDocuments() {
    const response = await api.get(`${resource}count-all`);
    return response;
  },
  async getValuesToFilter() {
    const response = await api.get(`${resource}values`);
    return response;
  },
  async deleteDocument(id) {
    const response = await api.delete(`${resource}?_id=${id}`);
    return response;
  },
  async patchDocument(body) {
    const response = await api.patch(`${resource}`, body.body, {
      params: { _id: body._id },
    });
    return response;
  },
};
