import Repository from '../../services/repositories/RepositoryFactory';

const Attributes = Repository.service('attributes');

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  state: {
    attributes: null,
  },
  // ----------------------------------------------------------------------------------
  getters: {
    showAttributes: (state) => state.documents,
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setAttributes(state, payload) {
      state.attributes = payload;
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    async getAllAttributes({ commit }) {
      try {
        const response = await Attributes.getAllAttributes();
        commit('setAttributes', response.body);
        return response;
      } catch (error) {
        return console.error(error);
      }
    },
  },
};
