import Vue from 'vue';
import Vuex from 'vuex';
import documents from './modules/documents';
import appLayout from './modules/appLayout';
import attributes from './modules/attributes';
import user from './modules/user';
import analysis from './modules/analysis';
import loading from './modules/loading';
import files from './modules/files';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    documents,
    appLayout,
    attributes,
    user,
    analysis,
    loading,
    files,
  },
});
