import axios from 'axios';

const api = axios.create({
  baseURL: process.env.VUE_APP_BACK_ROUTE_2,
  headers: {
    'Content-Type': 'application/json',
  },
});

export {
  api as default,
};
