export default {
  namespaced: true,
  state: {
    currentLink: null,
  },
  getters: {
    showCurrentLink: (state) => state.currentLink,
  },
  mutations: {
    setCurrentLink(state, newLink) {
      state.currentLink = newLink;
    },
  },
};
