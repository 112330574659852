export default {
  namespaced: true,
  //---------------------------------------------------
  state: {
    user: '',
  },
  //---------------------------------------------------
  getters: {
    showUser: (state) => state.user,
  },
  //---------------------------------------------------
  mutations: {
    setUser(state, payload) {
      state.user = payload.name;
    },
  },
};
