import Documents from './Documents';
import Attributes from './Attributes';
import Files from './Files';
import Analysis from './Analysis';

const repositories = {
  documents: Documents,
  attributes: Attributes,
  files: Files,
  analysis: Analysis,
};
export default {
  service: (name) => repositories[name],
};
