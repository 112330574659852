<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
body{
  margin: 0;
  padding: 0;
  font-family: $primary-font;
  font-size: 14.5px;
}
.app{
  display: grid;
  min-height: 100vh;
  grid-template-columns: auto 1fr;
  &__navigation-bar{
    position: sticky;
    top: 0;
  }
}

*::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}

*::-webkit-scrollbar-track {
    background-color: rgba(0,0,0 , 0.02);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0,0,0 , 0.3);
}
</style>
