export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    showLoading: (state) => state.loading,
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};
// The loading module is very simple. It only has a single state property called loading, and a single mutation called setLoading.
// The setLoading mutation is used to update the loading state property.
