import api from '../clients/axios';

const resource = 'files/';

export default {
  async getDocumentFile(path) {
    const response = await api.get(
      `${resource}?path=${path}`,
      {
        responseType: 'blob',
        transformResponse: [
          (data, headers) => {
            const blob = new Blob([data], {
              type: headers['content-type'],
            });
            const file = new File([blob], 'untitled.pdf', {
              type: blob.type,
            });
            return file;
          },
        ],
      },

    );
    return response;
  },
};
