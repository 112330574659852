import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const ls = new SecureLS({ isCompression: false });

export default {
  namespaced: true,
  // ----------------------------------------------------------------------------------
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  // ----------------------------------------------------------------------------------
  state: {
    documentView: true,
  },
  // ----------------------------------------------------------------------------------
  getters: {
    showDocumentView: (state) => state.documentView,
  },
  // ----------------------------------------------------------------------------------
  mutations: {
    setDocumentView: (state) => {
      Vue.set(state, 'documentView', !state.documentView);
    },
  },
  // ----------------------------------------------------------------------------------
  actions: {
    toggleDocumentView: ({ commit }) => {
      commit('setDocumentView');
    },
  },
};
