// ------------------- Service Imports ------------------- //
import Repository from "../../services/repositories/RepositoryFactory";

const ANALYSIS_SERVICE = Repository.service("analysis");

export default {
  namespaced: true,
  state: {
    analysisResult: null,
    selectedSeries: null,
    selectedChart: null,
  },
  mutations: {
    setAnalysisResult(state, result) {
      state.analysisResult = result;
    },
    setSelectedSeries(state, series) {
      state.selectedSeries = series;
    },
    setSelectedChart(state, chart) {
      state.selectedChart = chart;
    },
  },
  actions: {
    async fetchAnalysisResult({ commit }, {
      filter,
      series,
      source,
      chartType,
    }) {
      try {
        const RESULT = await ANALYSIS_SERVICE.getChart(filter, series, source, chartType);
        commit("setAnalysisResult", RESULT);
        return RESULT;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
};
